import React, { useState } from 'react';

const LocalContext = React.createContext();

function LocalContextProvider({ children }) {
  const API_URL = 'https://api.konnect.dev/api/v2';
  const SHORT_URL = 'https://u.konnect.dev/u';

  const [profile, setProfile] = useState([]);
  const [links, setLinks] = useState([]);
  const [urls, setURLs] = useState([]);

  return (
    <LocalContext.Provider
      value={{
        API_URL,
        SHORT_URL,
        profile,
        setProfile,
        links,
        setLinks,
        urls,
        setURLs,
      }}
    >
      {children}
    </LocalContext.Provider>
  );
}

export { LocalContextProvider, LocalContext };
