import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { LocalContext } from '../Context';

export default function NavBar() {
  const { profile, setProfile } = useContext(LocalContext);

  const history = useHistory();

  const logOut = (e) => {
    e.preventDefault();

    history.push('/');
    setProfile({});
  };

  return (
    <div className="w-full flex flex-row items-center justify-between bg-gray-900 py-2 lg:py-4 lg:px-8 border-b-2 border-gray-100">
      <div className="flex lg:w-1/2 w-full overflow-hidden lg:justify-start justify-center">
        <div className="lg:text-2xl text-lg font-bold text-gray-100 tracking-wider pr-2">
          Logged in as
        </div>
        <div className="lg:text-2xl text-lg font-bold text-yellow-400 tracking-wider underline">
          {profile.username}
        </div>
      </div>

      <button
        className="bg-red-600 hover:bg-red-500 focus:bg-red-500 rounded-lg lg:py-4 py-2 lg:px-8 px-20 font-bold text-red-100 lg:my-0 mt-4 mb-2 hidden"
        onClick={(e) => logOut(e)}
      >
        Log out
      </button>
    </div>
  );
}
