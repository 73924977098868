import React from 'react';
import { useHistory } from 'react-router-dom';

import NavBar from './NavBar';

export default function Apps() {
  const history = useHistory();

  const makeCard = (name, path) => (
    <button
      key={`button-${name}`}
      className="lg:w-100 w-full rounded-lg py-12 bg-blue-400 hover:bg-blue-500 focus:bg-blue-500 flex items-center justify-center lg:text-2xl text-xl font-bold tracking-wider text-blue-900 lg:mt-0 mt-2 ease-in-out duration-300 shadow-lg"
      onClick={() => history.push(path)}
    >
      {name}
    </button>
  );

  return (
    <div className="w-screen h-screen lg:overflow-y-hidden overflow-x-hidden bg-gray-700 flex flex-col items-start">
      <NavBar />
      <div className="flex flex-col w-full lg:mt-4 mt-2 lg:px-8 lg:grid lg:grid-flow-col lg:gap-4 grid-cols-4 px-2">
        {makeCard('Uploads', '/uploads')}
        {makeCard('URL Shortener', '/url')}
      </div>
    </div>
  );
}
