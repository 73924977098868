import React, { useContext, useState } from 'react';
import axios from 'axios';

import { LocalContext } from '../Context';

import NavBar from './NavBar';

export default function Uploads() {
  const { API_URL, profile, links, setLinks } = useContext(LocalContext);

  const [file, setFile] = useState(null);
  const [link, setLink] = useState('');

  const [message, setMessage] = useState('');

  const handleUploadImage = (e) => {
    e.preventDefault();
    if (e.target.files[0]) {
      if (e.target.files[0].size > 5000000) {
        alert('File too large!');
      } else {
        setFile(e.target.files[0]);
      }
    }
  };

  const uploadImage = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('file', file);

    setFile(null);

    axios.post(`${API_URL}/upload`, formData).then((res) => {
      if (res.data.error === 0) {
        const url = `${API_URL}/public${res.data.path}`;

        setLink(url);
        setFile(null);
        setMessage('');

        axios
          .post(
            `${API_URL}/link/create`,
            { uid: profile.uid, link: url },
            { headers: { Authorization: `Bearer ${profile.jwt}` } }
          )
          .then((response) => {
            if (response.data.error !== 0) {
              alert(response.data.message);
            } else {
              setLinks((prev) =>
                prev === undefined
                  ? [{ link: url, linkID: response.data.linkID }]
                  : [...prev, { link: url, linkID: response.data.linkID }]
              );
            }
          });
      } else {
        setFile(null);
        console.log(res.data);
      }
    });
  };

  const deleteLink = (linkID) => {
    axios
      .post(
        `${API_URL}/link/delete`,
        { uid: profile.uid, linkID: linkID },
        { headers: { Authorization: `Bearer ${profile.jwt}` } }
      )
      .then((res) => {
        if (res.data.error !== 0) {
          alert(res.data.message);
        } else {
          setLinks((prev) => prev.filter((l) => l.linkID !== linkID));
        }
      });
  };

  const makeItem = (id, url) => (
    <div
      className="rounded-lg w-full bg-blue-300 opacity-90 p-2 flex lg:h-20 h-16 justify-between mb-2 hover:bg-blue-200 focus:bg-blue-200 ease-in-out duration-300"
      key={id}
    >
      <button
        className="w-full flex h-full"
        onClick={() => {
          navigator.clipboard.writeText(url);
          setMessage('Link Copied!');
          setTimeout(() => setMessage(''), 1000);
        }}
      >
        <img src={url} alt="item" className="w-1/3 object-scale-down h-full" />
        <div className="w-3/5 lg:text-md text-xs overflow-hidden flex items-center text-blue-900">
          {url.split('/').slice(-1)[0]}
        </div>
      </button>
      <button
        className="bg-red-400 text-red-900 lg:text-xl text-lg ri-delete-bin-2-line p-2 rounded-full lg:w-16 w-12 focus:bg-red-500 hover:bg-red-500 ease-in-out duration-300"
        onClick={() => deleteLink(id)}
      ></button>
    </div>
  );

  return (
    <div className="w-screen h-screen lg:overflow-y-hidden overflow-x-hidden bg-gray-700 flex flex-col items-start">
      <NavBar />
      <div className="w-full flex lg:flex-row flex-col items-center lg:justify-around lg:h-full">
        <div className="lg:w-1/3 lg:mt-0 mt-4 w-11/12 bg-gray-600 rounded-lg p-2 lg:h-full flex flex-col items-center justify-center">
          <input
            type="file"
            name="image"
            accept=".jpg,.jpeg,.png,.svg,.gif,.bmp"
            className="lg:w-4/5 w-11/12 p-2 text-gray-100 bg-gray-700 border-2 border-gray-600 rounded-lg overflow-hidden lg:text-md text-xs"
            onChange={(e) => {
              e.persist();
              e.preventDefault();
              handleUploadImage(e);
            }}
            multiple
          />

          <button
            className={`mt-2 lg:w-3/5 w-11/12 p-2 bg-gray-900 ${
              file ? 'hover:bg-blue-900 focus:bg-blue-900' : 'opacity-50'
            } rounded-lg font-bold tracking-wide lg:text-lg text-sm text-gray-200 ease-in-out duration-300`}
            onClick={(e) => (file ? uploadImage(e) : null)}
          >
            Upload
          </button>

          {link.length > 0 ? (
            <img
              alt="uploads"
              src={link}
              className="w-5/6 object-scale-down mt-4 max-h-sm"
            />
          ) : (
            ''
          )}
        </div>
        <div className="lg:w-1/2 lg:mt-0 mt-4 w-11/12 bg-gray-800 lg:h-full flex flex-col items-center rounded-lg p-2">
          <div className="w-full text-center mt-2 mb-4 text-gray-300 lg:text-3xl text-lg font-bold tracking-wide">
            Uploads
          </div>
          <div className="w-full flex flex-col items-center lg:overflow-y-scroll lg:h-3/4">
            {links !== undefined &&
              links.map((l) => makeItem(l.linkID, l.link))}
          </div>
          <div className="w-full text-center mt-2 text-green-300 lg:text-lg text-md tracking-wide">
            {message}
          </div>
        </div>
      </div>
    </div>
  );
}
