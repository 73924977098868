import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import { LocalContext } from '../Context';

import logo from '../Assets/images/Logo.png';
import background from '../Assets/images/Background.png';

export default function Login() {
  const { API_URL, setProfile, setLinks, setURLs } = useContext(LocalContext);

  const [error, setError] = useState('');
  const [color, setColor] = useState('blue');

  const history = useHistory();

  useEffect(() => {
    let url = window.location.href;
    if (url.includes('uid') && url.includes('jwt')) {
      let uid = window.location.href.split('uid=')[1];
      if (uid.includes('&')) {
        uid = uid.split('&')[0];
      }

      let jwt = window.location.href.split('jwt=')[1];
      if (jwt.includes('&')) {
        jwt = jwt.split('&')[0];
      }

      let t = window.location.href.split('theme=')[1];
      if (t.includes('&')) {
        t = t.split('&')[0];
      }

      let lang = window.location.href.split('lang=')[1];
      if (lang.includes('&')) {
        lang = lang.split('&')[0];
      }
      if (lang.includes('#/')) {
        lang = lang.split('#/')[0];
      }

      localStorage.setItem(
        '_userData',
        JSON.stringify({
          uid: uid,
          jwt: jwt,
          theme: t ? t : 'dark',
          lang: lang ? lang : 'en',
        })
      );

      window.location.href = url.split('?')[0];
    }

    if (JSON.parse(localStorage.getItem('_userData'))) {
      const { uid, jwt } = JSON.parse(localStorage.getItem('_userData'));

      if (uid && jwt) {
        forceLogin(uid, jwt);
      } else {
        localStorage.clear();
      }
    }
  });

  const forceLogin = (uid, jwt) => {
    setColor('blue');
    setError('Submitting...');

    const data = {
      uid,
    };

    axios
      .post(`${API_URL}/user/login/jwt`, data, {
        headers: { Authorization: `Bearer ${jwt}` },
      })
      .then((res) => {
        if (res.data.error !== 0) {
          setColor('red');
          setError(res.data.message);

          setTimeout(() => {
            setError('');
          }, 2000);
        } else {
          setColor('green');
          setError('Logging in...');

          setProfile({
            uid: res.data.uid,
            name: res.data.name,
            username: res.data.username,
            jwt: res.data.jwt,
          });

          axios
            .post(
              `${API_URL}/link/fetch`,
              { uid: res.data.uid },
              { headers: { Authorization: `Bearer ${res.data.jwt}` } }
            )
            .then((response) => {
              if (response.data.error === 0) {
                setLinks(response.data.links);
              }

              axios
                .post(
                  `${API_URL}/url/fetch`,
                  { uid: res.data.uid },
                  { headers: { Authorization: `Bearer ${res.data.jwt}` } }
                )
                .then((response2) => {
                  if (response2.data.error === 0) {
                    setURLs(response2.data.urls);
                  }

                  setError('');
                  history.push('/apps');
                });
            });
        }
      });
  };

  return (
    <div className="w-full h-full flex items-center justify-center bg-gray-700">
      <div className="lg:w-1/3 w-5/6 flex flex-col items-center justify-center bg-gray-900">
        <img
          src={background}
          alt="Background"
          className="fixed h-screen w-full bg-gray-900 z-0 object-cover"
        />

        <div className="w-full flex flex-col items-center justify-center z-50 bg-gray-900 py-8 rounded-lg border-2 border-blue-500 opacity-95">
          <div className="lg:text-4xl text-4xl tracking-wide font-bold font-sans text-yellow-300 w-full text-center my-4">
            Login
          </div>

          <img
            className="lg:w-2/5 w-3/5 rounded-full p-2 border-4 border-blue-400 mb-2"
            src={logo}
            alt=""
          />

          {error.length > 0 ? (
            <div
              className={`w-full text-center lg:text-2xl text-lg mt-4 ${
                color === 'blue'
                  ? 'text-blue-400'
                  : color === 'green'
                  ? 'text-green-400'
                  : 'text-red-400'
              }`}
            >
              {error}
            </div>
          ) : (
            <div className="w-full px-3 lg:px-0">
              <a
                className={`mt-3 font-gilroy tracking-wide text-main-300 justify-center w-full lg:w-auto text-opacity-75 uppercase font-bold flex items-center sm:px-8 py-3 bg-main-900 rounded-lg bg-opacity-75 hover:bg-opacity-90 focus:bg-opacity-90 outline-none`}
                href={`https://sso.konnect.dev/?origin=${
                  window.location.href.split('?')[0]
                }`}
              >
                Login via SSO
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
