import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { LocalContext } from './Context';

import Login from './Components/Login';
import Apps from './Components/Apps';
import Uploads from './Components/Uploads';
import Links from './Components/Links';

export default function App() {
  const { profile } = useContext(LocalContext);

  return (
    <div className="w-full h-screen overflow-hidden flex justify-center items-center">
      <Switch>
        <Route exact path="/">
          <Login />
        </Route>

        {profile.jwt ? (
          <Switch>
            <Route exact path="/apps">
              <Apps />
            </Route>

            <Route exact path="/uploads">
              <Uploads />
            </Route>

            <Route exact path="/url">
              <Links />
            </Route>
          </Switch>
        ) : (
          ''
        )}

        <Route render={() => <Redirect to="/" />} />
      </Switch>
    </div>
  );
}
