import React, { useContext, useState } from 'react';
import axios from 'axios';

import { LocalContext } from '../Context';

import NavBar from './NavBar';

export default function Links() {
  const [url, setURL] = useState('');
  const [miniURL, setMiniURL] = useState('<empty>');
  const [message, setMessage] = useState('');

  const { API_URL, SHORT_URL, profile, urls, setURLs } =
    useContext(LocalContext);

  const getURL = () => {
    setMiniURL('Processing...');

    axios
      .post(
        `${API_URL}/url/create`,
        { uid: profile.uid, originalURL: url },
        { headers: { Authorization: `Bearer ${profile.jwt}` } }
      )
      .then((res) => {
        if (res.data.error !== 0) {
          setMiniURL('<empty>');
          alert(res.data.message);
        } else {
          setURLs((prev) =>
            prev === undefined
              ? [
                  {
                    original: url,
                    urlID: res.data.urlID,
                    shorten: res.data.shorten,
                  },
                ]
              : [
                  {
                    original: url,
                    urlID: res.data.urlID,
                    shorten: res.data.shorten,
                  },
                  ...prev,
                ]
          );

          setMiniURL(`${SHORT_URL}/${res.data.shorten}`);
        }
      });
  };

  return (
    <div className="w-screen h-screen lg:overflow-y-hidden overflow-x-hidden bg-gray-700 flex flex-col items-start">
      <NavBar />
      <div className="w-full flex lg:flex-row flex-col items-center text-gray-100 font-bold text-xl lg:pl-8 px-2 lg:pr-0">
        <div className="w-full lg:w-1/3 flex flex-col">
          <input
            type="text"
            name="url"
            placeholder="Enter Original URL..."
            className="rounded-lg p-2 bg-gray-800 text-gray-200 placeholder-gray-300 lg:text-base text-base w-full mt-4"
            value={url}
            onChange={(e) => setURL(e.target.value)}
          />

          <button
            className={`mt-2 w-full p-2 bg-gray-900 ${
              url ? 'hover:bg-blue-900 focus:bg-blue-900' : 'opacity-50'
            } rounded-lg font-bold tracking-wide lg:text-lg text-sm text-gray-200 ease-in-out duration-300`}
            onClick={(e) => (url ? getURL(e) : null)}
          >
            GET URL
          </button>

          <div className="mt-2 w-full flex items-center">
            <div className="w-full bg-gray-800 h-full font-normal text-base p-1 flex items-center py-3 rounded-lg">
              {miniURL}
            </div>
            <button
              className="bg-yellow-400 text-yellow-900 lg:text-xl text-lg ri-clipboard-line p-2 rounded-full lg:w-12 w-12 ml-2 focus:bg-yellow-500 hover:bg-yellow-500 ease-in-out duration-300"
              onClick={() => {
                navigator.clipboard.writeText(miniURL);
                setMessage('Link Copied!');
                setTimeout(() => setMessage(''), 1000);
              }}
            ></button>
          </div>

          <div className="w-full text-center mt-2 text-green-300 lg:text-lg text-md tracking-wide">
            {message}
          </div>

          <div className="mt-2 px-2 pt-2 bg-gray-800 rounded-lg w-full">
            {urls.map((url) => (
              <div
                className="rounded-lg w-full bg-blue-300 opacity-90 p-2 h-8 font-normal text-blue-900 justify-between mb-2 hover:bg-blue-200 focus:bg-blue-200 ease-in-out duration-300 text-base flex items-center"
                key={url.urlID}
                onClick={() => {
                  navigator.clipboard.writeText(`${SHORT_URL}/${url.shorten}`);
                  setMessage('Link Copied!');
                  setTimeout(() => setMessage(''), 1000);
                }}
              >
                {url.original}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
